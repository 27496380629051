/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-09-26 18:27:36
 */
<template>
  <van-overlay
    style="background: rgba(0,0,0,0.3)"
    :show="showReg"
    @click="showReg = false"
  >
    <div
      class="wrapper"
      @click.stop="showReg = false"
    >
      <div
        class="block"
        @click.stop
      >
        <template v-if="pageStatus === 'reg'">
          <div class="text-a">
            请先注册账号
          </div>
          <van-field
            v-model="reg.mobile"
            class="text-c"
            maxlength="11"
            type="number"
            placeholder="输入手机号"
            label="手机号"
          />
          <van-field
            v-model="reg.code"
            class="text-c"
            maxlength="6"
            placeholder="输入验证码"
            label="验证码"
          >
            <template #button>
              <span
                class="hr-btn"
                @click="sendCode"
              >{{ codeStatus ? '发送验证码' : codeTime }}</span>
            </template>
          </van-field>
          <van-checkbox v-model="userRadio">
            <span
              class="user-in-doc"
              @click.stop="goUserDoc"
            >青童季用户注册协议</span>
          </van-checkbox>

          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="codeSave"
          >
            提交
          </van-button>
        </template>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'RegBox',
  components: {
  },
  data () {
    return {
      showReg: false,
      userRadio: '',
      reg: {
        mobile: '',
        code: ''
      },
      codeTime: 60,
      codeStatus: true,
      pageStatus: 'reg'
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    goUserDoc () {
      window.open('https://img.tjerong.cn/base/reg.html')
    },
    show () {
      this.showReg = true
    },
    hide () {
      this.showReg = false
      this.reg = {
        mobile: '',
        code: ''
      },
      this.codeTime = 60
      this.codeStatus = true
      this.pageStatus = 'reg'
    },
    async codeSave () {
      if (!this.userRadio) {
        this.$toast('请先同意注册协议')
        return
      }
      if (this.reg.mobile && this.reg.mobile.length === 11 && this.reg.code) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });
        let res = await this.$http.userApi.mobileBind(this.reg)
        if (res.code === 200) {
          this.$toast.clear()
          this.$emit('codeEvent', {msg: 'success'})
          this.hide()
        }
      } else {
        this.$toast('请完善信息')
      }
    },
    sendCodeTime () {
      if (this.codeTime > 0) {
        this.codeTime --
        setTimeout(() => {
          this.sendCodeTime()
        }, 1000);
      } else {
        this.codeTime = 60
        this.codeStatus = true
      }
    },
    async sendCode () {
      if (!this.codeStatus) {
       return   
      }
      if (this.reg.mobile && this.reg.mobile.length === 11) {
        let data = {mobile: this.reg.mobile}
        let res = await this.$http.userApi.sendCode(data)
        if (res.code === 200) {
          this.$toast.success('发送成功')
          this.codeStatus = false
          this.sendCodeTime()
        }
      } else {
        this.$toast('请完善手机号')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .text-a {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .block {
      width: 294px;
      height: 270px;
      background: #fff;
      padding-top: 30px;
      text-align: center;
      border-radius: 5px;
    }
    .code-btn {
      width: 150px;
      height: 40px;
      font-size: 15px;
      margin-top: 36px;
    }
    .user-doc {
      padding-left: 18px;
      padding-top: 10px;
      font-size: 12px;
      .user-in-doc {
        font-size: 12px;
        color: #0c64eb;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
</style>
